@import "webfonts";

$font-family-sans-serif: "Formular", sans-serif;
$body-bg: #fff;
$header-bg: #fff;
$primary: #7848FF;
$secondary: #142433;
$dark: #142433;
$light: #E5E8F5;
$blue: #519DFC;
$success: #2CD48E;
$danger: #FF4859;